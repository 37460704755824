import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          Welcome to Plik Post. 
        </p>
        <p>
          An automated platform for brands to reward everday users for promoting their products.
        </p>
        <p>
          Powered by AI.
        </p>
        <p>
          Coming Soon...
        </p>
      </header>
    </div>
  );
}

export default App;
